<!--  -->
<template>
    <div>

        <div class="bottom">
            <hr>
            <div class="bottom_title">
                联系我们
            </div>
            <div class="bottom_title1"  v-if="type=='1'">
                <!-- 杭州灏景教育科技有限公司海南分公司 -->
                {{ pConfiglist.company_name }}
            </div>
            <div class="bottom_title11" v-else>
                
                <span> CONTACT US</span>
                <i></i>
            </div>

            <div class="bottom_imgs">
                <div class="bottom_img">
                    <img src="../assets/i20.jpg" class="img1" alt="">
                </div>

                <div class="bottom_img1s">
                    <div class="bottom_img1">

                        <img src="../assets/32.png" class="img2">
                        <img src="../assets/33.png" class="img2">


                    </div>
                    <div class="bottom_img1">


                        <img src="../assets/34.png" class="img2">
                        <img src="../assets/35.png" class="img2">


                    </div>
                    <div class="bottom_img1">


                        <img src="../assets/36.png" class="img2">
                        <img src="../assets/37.png" class="img2">

                    </div>
                </div>


            </div>
            <div class="bottom_title2" v-if="type=='1'">
                <div class="bottom_title21">
                    特别声明：我们只是代客户提交商户、企业位置资料，不是地图标注平台方，所提供服务为商业有偿帮助咨询服务，全程都是人工提交资料，自身并不能对第三方网站的原始内容进行编辑，请知悉。

                </div>


            </div>
        </div>
    </div>
</template>

<script>

import { pConfig } from '../request/api'
export default {
    name: "Bottom",
    props: {//props列表
        type: String,//定义参数类型

    },
    data() {
        return {
            pConfiglist:{}
        }
    },
    mounted(){
        this.pConfigdata()
    },
    methods: {
        async pConfigdata() {
            await  pConfig({

            }).then(res => {
                console.log(res, 'res.datares.data')
                if (res) {



                    this.pConfiglist = res.data


                }

            })
        },
    }
}

</script>

<style scoped>
.bottom {
    padding: 0 15px;
    padding-bottom: 100px;

}

.bottom_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 1.5;
    color: #212529;
    padding: 10px 0;

}

.bottom_title1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 1rem;

    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 1.5;
    color: #9f9f9f;
    text-align: center;

    padding: 10px 0;

}
.bottom_title11 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 1rem;

    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 1.5;
    color: #9f9f9f;
    text-align: center;
    color: #9f9f9f;
    text-align: center;
    position: relative;
    padding: 10px 0;

}
.bottom_title11 i {
    display: block;
    position: absolute;
    top: 10px;
    left: 30%;
    right: 30%;
    border-bottom: 1px solid #eee;
    height: 15px;
    z-index: -1;
}
.bottom_title11 span {
    background: #fff;
    padding: 0 30px;
    display: inline-block;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.bottom_imgs {
    display: flex;
    padding: 0 15px;
    margin: 30px auto;
    align-items: center;
    justify-content: center;
}

.bottom_img {
    width: 200px;
    margin-right: 50px;
}
.bottom_img1s{
    width: 200px;
}
.bottom_img1 {
    width: 100%;
    /* display: flex;
     margin: 10px auto; */
     display: grid;
    text-align: center;

    grid-template-columns: repeat(2, 1fr);
    /* grid-row-gap: 20rpx; */
    grid-column-gap: 20px;

}

.img1 {
    width: 100%;
    height: auto;
}

.img2 {
    width: 100%;
    margin: 0 10px 10px 0;
    height: auto;
    overflow: hidden;
}

.bottom_title2 {
    display: flex;
    /* margin: 10px auto; */
    align-items: center;
    justify-content: center;
}

.bottom_title21 {
    width: 80%;
    background: #f2f2f2;
    padding: 8px;
    border-radius: 6px;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
</style>
