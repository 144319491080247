<!--  -->
<template>
    <div class="bg">
        <TopVue></TopVue>
        <div class="leixing">
            <div class="leixing_t">
                请选择要代标注的地图类型
            </div>
            <div class="leixing_b">
                <div class="leixing_box" v-for="(item, index) in initData" :key="index" @click="gopage()">
                    <div class="leixing_boxt">
                        <img :src="item.image_text" class="w70" alt="">

                        <!-- <img src="@/assets/baidu.png" class="w70" alt="" v-if="index == 0">
                        <img src="@/assets/tengxun.png" class="w70" alt="" v-if="index == 1">
                        <img src="@/assets/gaode.png" class="w70" alt="" v-if="index == 2">
                        <img src="@/assets/weixin.png" class="w70" alt="" v-if="index == 3">
                        <img src="@/assets/didi.jpeg" class="w70" alt="" v-if="index == 4">
                        <img src="@/assets/douyin.jpg" class="w70" alt="" v-if="index == 5">
                        <img src="@/assets/beidou.png" class="w70" alt="" v-if="index == 6">
                        <img src="@/assets/apple.jpeg" class="w70" alt="" v-if="index == 7">
                        <img src="@/assets/four.png" class="w70" alt="" v-if="index == 8">
                        <img src="@/assets/qita.png" class="w70" alt="" v-if="index == 9"> -->
                        <div class="leixing_boxtitle">
                            {{ item.name }}- {{ item.title }}
                        </div>
                    </div>
                    <div class="leixing_boxb">
                        <div>

                        </div>
                        <div  class="flex-box">
                            <div style="margin-right: 10px;">标注入口</div>

                            <img src="../assets/type_more.png" class="jiantou" alt="">
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="cishu">

            <div class="cishu_b" v-if="screenWidth >= 801">
                <div class="cishu_box" v-for="(item, index) in initData2" :key="index" @click="gopage()">
                    <div class="cishu_boxt">


                        <div class="cishu_boxtitle">
                            {{ item.title }}
                        </div>
                        <div class="cishu_boxtitle1 flex-box">
                            <img :src="item.image_text" class="w28" alt="">
                            <!-- <img src="@/assets/tj1.png" class="w28" alt="" v-if="index == 0">
                            <img src="@/assets/tj2.png" class="w28" alt="" v-if="index == 1">
                            <img src="@/assets/tj3.png" class="w28" alt="" v-if="index == 2">
                            <img src="@/assets/tj4.png" class="w28" alt="" v-if="index == 3"> -->
                            {{ item.number }}
                        </div>
                    </div>


                </div>
            </div>
            <div class="cishu_b1" v-else>
                <div class="cishu_box1" v-for="(item, index) in initData2" :key="index" @click="gopage()">
                    <div class="cishu_boxt">


                        <div class="cishu_boxtitle">
                            {{ item.title }}
                        </div>
                        <div class="cishu_boxtitle1 flex-box">
                            <img :src="item.image_text" class="w28" alt="">
                            <!-- <img src="@/assets/tj1.png" class="w28" alt="" v-if="index == 0">
                            <img src="@/assets/tj2.png" class="w28" alt="" v-if="index == 1">
                            <img src="@/assets/tj3.png" class="w28" alt="" v-if="index == 2">
                            <img src="@/assets/tj4.png" class="w28" alt="" v-if="index == 3"> -->
                            <div> {{ item.number }}</div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
        <div class="biaozhu">
            <div class="biaozhu_t">
                为什么要做地图标注
            </div>
            <div class="biaozhu_b" v-if="screenWidth >= 801">
                <div class="biaozhu_box" v-for="(item, index) in initData1" :key="index" @click="gopage()">
                    <div class="biaozhu_boxt">
                        <img :src="item.image_text" class="w250" alt="">
                        <!-- <img src="@/assets/why1.jpg" class="w250" alt="" v-if="index == 0">
                        <img src="@/assets/why2.jpg" class="w250" alt="" v-if="index == 1">
                        <img src="@/assets/why3.jpg" class="w250" alt="" v-if="index == 2">
                        <img src="@/assets/why4.jpg" class="w250" alt="" v-if="index == 3"> -->

                        <div class="biaozhu_boxtitle">
                            {{ item.title }}
                        </div>
                        <div class="biaozhu_boxtitle1">
                            {{ item.intro }}
                        </div>
                    </div>

                </div>
            </div>
            <div class="biaozhu_b1" v-else>
                <div class="biaozhu_box" v-for="(item, index) in initData1" :key="index" @click="gopage()">
                    <div class="biaozhu_boxt">
                        <img :src="item.image_text" class="w250" alt="">
                        <!-- <img src="@/assets/why1.jpg" class="w250" alt="" v-if="index == 0">
                        <img src="@/assets/why2.jpg" class="w250" alt="" v-if="index == 1">
                        <img src="@/assets/why3.jpg" class="w250" alt="" v-if="index == 2">
                        <img src="@/assets/why4.jpg" class="w250" alt="" v-if="index == 3"> -->

                        <div class="biaozhu_boxtitle">
                            {{ item.title }}
                        </div>
                        <div class="biaozhu_boxtitle1">
                            {{ item.intro }}
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="anli">
            <div class="anli_t">
                效果案例
            </div>
            <div class="anli_t1">
                能够看到的真实案例，让您好的门店更好找。
            </div>
            <div class="anli_b" v-if="screenWidth >= 801">
                <div class="anli_box" v-for="(item, index) in initData3" :key="index" @click="gopage()">
                    <div class="anli_boxt">

                        <img :src="item.image_text" class="w250" alt="">


                    </div>

                </div>
            </div>
            <div class="anli_b1" v-else>
                <div class="anli_box" v-for="(item, index) in initData3" :key="index" @click="gopage()">
                    <div class="anli_boxt">

                        <img :src="item.image_text" class="w250" alt="">


                    </div>

                </div>
            </div>
        </div>
        <div class="rukou">
            <div class="rukou_t" @click="gopage()">
                代标注入口
            </div>
        </div>



        <BottomVue :type="'1'"></BottomVue>
        <div class="rukou1">
            <!-- <a href="" target="_blank"
                class="rukou1_t">咨询办理</a> -->
            <div class="rukou1_t">咨询办理</div>
        </div>

    </div>
</template>

<script>


import TopVue from './Top.vue'
import BottomVue from './Bottom.vue'
// import TopVue

import { mapList, mapSubmit, mapWhy, mapCase } from '../request/api'
export default {
    name: 'MenuPage',
    data() {
        return {
            initData: [
                //     {

                //     icon: '@/assets/baidu.png',
                //     title: '百度 - 公司/店铺地图标注'
                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '腾讯 - 公司/店铺地图标注'
                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '高德 - 公司/店铺地图标注'
                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '微信 - 公司/店铺地图标注'
                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '滴滴 - 公司/店铺地图标注'
                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '抖音 - 公司/店铺地图标注'
                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '微信百度 - 公司/店铺地图标注'
                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '高德微信 - 公司/店铺地图标注'
                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '常用四款 - 公司/店铺地图标注'
                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '全部 - 公司/店铺地图标注'
                // }
            ],
            screenWidth: document.body.clientWidth,//初始化宽度







            initData1: [
                //     {

                //     icon: '@/assets/baidu.png',
                //     title: '一键导航上门',
                //     title1: '地图标注可实现客户一键导航精准到店'

                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '方便客户查找',
                //     title1: '方便客户主动查找位置，吸引顾客到店。'
                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '提示企业知名度',
                //     title1: '提升企业知名度、曝光率、提升品牌效应等。'
                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '发送精准路线导航',
                //     title1: '快捷通过微信发送准确路线一键导航。'
                // }
            ],








            initData2: [
                //     {

                //     icon: '@/assets/baidu.png',
                //     title: ' 67538次',
                //     title1: '累计门店地图提交数'

                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '234252次',
                //     title1: '累计企业地图提交数'
                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '5333次',
                //     title1: '累计工厂地图提交数'
                // }, {
                //     icon: '@/assets/baidu.png',
                //     title: '99.5%',
                //     title1: '代标注审核通过率'
                // }
            ],
            initData3: [],
            names: [], // 获取名称
            values: [], // 获取数值
            total: 0, // 总和
            pConfiglist: ''



        }
    },
    components: {
        TopVue,
        BottomVue
    },
    created() {
        // this.getdata1()
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    mounted() {
        const that = this

        that.show = true
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                window.screenHeight = document.body.innerHeight
                that.screenWidth = window.screenWidth
                that.screenHeight = window.innerHeight
            })()
        }
        // that.pConfigdata()
        that.getdata1()
        that.getdata2()
        that.getdata3()
        that.getdata4()







        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;  // 注意月份从0开始，需要加1
        let day = currentDate.getDate();

        // 输出当前日期
        console.log(year + "-" + month + "-" + day);
        // console.log(this.$route.params.houseid,'hhhhhhhhhhhhhhhhhhhhhhhhhhhhh'); // 输出路由参数

    },

    watch: {

        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenWidth = val
            // this.timer = true
        },
        screenHeight(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenHeight = val
            // this.timer = true
        },



    },
    methods: {

        gopage() {
            this.$router.push('/SendPage');
        },
        async getdata1() {
            await mapList({

            }).then(res => {
                console.log(res, 'res.datares.data')
                if (res) {



                    this.initData = res.data


                }

            })
        },
        async getdata2() {
            await mapSubmit({

            }).then(res => {
                console.log(res, 'res.datares.data')
                if (res) {



                    this.initData2 = res.data


                }

            })
        },
        async getdata3() {
            await mapWhy({

            }).then(res => {
                console.log(res, 'res.datares.data')
                if (res) {



                    this.initData1 = res.data


                }

            })
        },
        async getdata4() {
            await mapCase({

            }).then(res => {
                console.log(res, 'res.datares.data')
                if (res) {



                    this.initData3 = res.data


                }

            })
        },

    }
}


</script>

<style scoped lang="scss">
.bg {
    margin: 0;
    width: 100vw;


    /* background-color: #0f2461; */
    background-color: rgba(255, 255, 255, 0.5);



}

.jiantou {
    width: 10px;
    height: 20px;
}


.leixing {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.leixing_t {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-size: 1rem;

    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;


    text-align: center;

    padding: 10px 0;
}

.leixing_b {
    background: #FFFFFF;

    opacity: 1;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(2, 1fr);
    /* grid-row-gap: 20rpx; */
    grid-column-gap: 20px;
}

.leixing_box {

    margin: 0 2% 10px;
    border: 1px solid #e7e7eb;
    background-color: #fff;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        div {
            background-color: #f4f5f9 !important;
        }
    }

}

.leixing_boxt {
    padding: 16px 6px;
}


.leixing_boxtitle {
    padding: 16px 6px;
    font-size: 1.2rem;
    color: #222;
    text-align: center;
    font-weight: 400;
    line-height: 1.5;
}

.leixing_boxb {
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    text-align: right;
    border-top: 1px solid #e7e7eb;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

}

.biaozhu {

    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.biaozhu_t {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 1.5;
    color: #212529;
    padding: 10px 0;

}



.biaozhu_b {
    margin: 30px 0;
    background: #FFFFFF;

    opacity: 1;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(4, 1fr);
    /* grid-row-gap: 20rpx; */
    grid-column-gap: 20px;

}

.biaozhu_b1 {
    margin: 30px 0;
    background: #FFFFFF;

    opacity: 1;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(2, 1fr);
    /* grid-row-gap: 20rpx; */
    grid-column-gap: 20px;

}

.biaozhu_box {

    margin: 0 2% 10px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        img {
            border-radius: 0;
            transform: scale(1.1);
        }
    }

}

.biaozhu_boxtitle {
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
    color: #111;
    padding: 6px 0;
}

.anli {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.anli_t {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 1.5;
    margin-top: 10px;

    color: #212529;
    padding: 10px 0;

}

.anli_t1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 1rem;
    margin-top: 10px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 1.5;
    color: #9f9f9f;
    text-align: center;

    padding: 10px 0;
}

.w250 {
    width: 100%;
    max-height: auto;
    border-radius: 8px;


}

.anli_b {
    margin: 30px 0;
    background: #FFFFFF;

    opacity: 1;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;

}

.anli_b1 {
    margin: 30px 0;
    background: #FFFFFF;

    opacity: 1;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(2, 1fr);
    // grid-row-gap: 20px;
    grid-column-gap: 20px;

}

.anli_box {

    margin: 0 2% 10px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        img {
            border-radius: 0;
            transform: scale(1.1);
        }
    }

}

.anli_boxtitle {
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
    color: #111;
    padding: 6px 0;
}

.cishu {
    width: 98%;
    margin-top: 20px;
    padding: 40px 0 30px;
    background: #fff;
    padding: 20px 0;
    clear: both;
    border: 4px solid #f7f7f7;
    border-left: 0;
    border-right: 0;

}

.cishu_t {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 1.5;
    color: #212529;
    padding: 10px 0;

}



.cishu_b {
    margin: 30px 0;
    background: #FFFFFF;

    opacity: 1;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;

}

.cishu_b1 {
    margin: 30px 0;
    background: #FFFFFF;

    opacity: 1;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;

}

.w28 {
    margin-right: 10px;
}

.cishu_box {
    background: url(../assets/bgx.jpg) no-repeat 100% 0;
    text-align: center;
    padding-right: 60px;
}

.cishu_box1 {
    // background: url(../assets/bgx.jpg) no-repeat 100% 0;
    text-align: center;
    // padding-right: 60px;
}

.cishu_boxtitle {
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 1.5;
    color: #212529;
    padding: 10px 0;
}

.cishu_boxtitle1 {
    align-items: center;
    justify-content: center;
}

.rukou {
    width: 98%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;

    margin-left: auto;
    padding: 40px 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.rukou_t {

    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: inline-block;
    background: url(../assets/wm2.gif) no-repeat 0 0;
    background-size: 115%;
    border-radius: 5px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 1rem;
    color: #fff;
    padding: 0 50px;
}

.rukou_t:hover {
    text-decoration: underline;
    /* 悬停时去除横线 */
}

.rukou1 {
    width: 98%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    position: fixed;
    bottom: 0;
    margin-left: auto;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
}

.rukou1_t {

    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: inline-block;

    display: block;
    background: #1a86ff;
    color: #fff;
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
    padding: 10px 0;
    border-radius: 5px;
    text-decoration: none;
    /* 去除文本下划线 */
    /* 针对不同浏览器的伪元素 */
    text-decoration: none;
}


.rukou1_t:hover {
    text-decoration: underline;
    /* 悬停时去除横线 */
}

.rukou1_t::before,
.rukou1_t::after {
    text-decoration: none;
    /* 确保伪元素没有下划线 */
}

.rukou1_t:hover::before,
.rukou1_t:hover::after {
    text-decoration: underline;
    /* 悬停时去除伪元素的横线 */
}
</style>
