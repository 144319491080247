<template>
    <div class="app">
     
        <div class="content" @scroll="onScroll">
  

        <div class="image-container" :class="{ 'animate': isAnimated }" :style="{ top: scrollTop + 'px' }">
         
          <img src="@/assets/clickbtn_4.png" class="wkefu" alt="">
        </div>
      </div>
    </div>
  </template>
   
  <script>
  export default {
    data() {
      return {
        isAnimated: false,
        headerHeight: 0,
        scrollTop:0
      };
    },
    methods: {
        onScroll(event) {
        // 在这里处理滚动事件
       
        console.log('event', event)
        const scrollTop = event.target.scrollTop;
        this.scrollTop=scrollTop+300
        if (scrollTop > this.headerHeight && !this.isAnimated) {
          this.isAnimated = true;
        } else if (scrollTop <= this.headerHeight && this.isAnimated) {
          this.isAnimated = false;
        }
      }
    },
    mounted() {
      this.headerHeight = this.$refs.header.clientHeight;
      console.log(' this.headerHeight ',  this.headerHeight )
    }
  };
  </script>
   
  <style scoped>
 
  .content {
    height: 100vh; /* 内容区域高度，可以根据需要调整 */
    overflow-y: scroll;
    position: relative;
  }
   
  .image-container {
    position: absolute;
    top: 100px; /* 图片初始位置 */
    width: 100%;
    right: 40px;
    width: 180px;
    transition: top 3s ease;
  }
   
  .image-container.animate {
    
  }
  .wkefu{
    width: 180px;
    height: 200px;
  }
  </style>