<!--  -->
<template>
    <div class="bg">
     

        <TopVue></TopVue>
        <div class="topxinxi">
            <div class="top_title">
                地图标注信息:
            </div>
            <div class="top_title1">
                申请说明：地图位置定位入驻需要人工上传核对审核资料，需要支付人工标注服务费用，此费用为一次性收费，长期有效！
                选择合适您的套餐，提交支付后您申请的店铺或企业地图位置入驻信息，将会实时在地图上显示并搜索的到！
            </div>
            <div class="top_box">
                <div class="top_box_l">
                    商户标注名称:

                </div>
                <div class="top_box_r">
                    {{ userData.name }}
                </div>
            </div>
            <div class="top_box">
                <div class="top_box_l">

                    标注详细地址:

                </div>
                <div class="top_box_r">
                    {{ userData.address }}
                </div>
            </div>
            <div class="top_box">
                <div class="top_box_l">

                    申请人电话:

                </div>
                <div class="top_box_r">
                    {{ userData.phone }}
                </div>
            </div>

        </div>

        <div class="ge"></div>
        <div class="mid">
            <div class="mid_t">
                ↓↓↓点击下方选择所需地图 ↓↓↓
            </div>
            <div class="mid_m">
                <div style="text-align: center; margin-top: 30px; color: red;font-size: 1.25rem;">请点击选择您适合的地图套餐：
                    <select style="width: 60%; height:50px;font-size: 1.25rem;padding: 10px;" v-model="map_id">

                        <option v-for="(item) in initData" :key="item.id" :value="item.id">{{ item.name }}</option>
                        <!-- <option value="1">腾讯地图</option>
                        <option value="2">高德地图</option>
                        <option value="3">微信地图</option>
                        <option value="4">滴滴地图</option>
                        <option value="5">抖音地图</option>
                        <option value="6">微信百度(热门)</option>
                        <option value="7">高德微信(热门)</option>
                        <option value="8">最常用四款(高德+微信+百度+腾讯)</option>
                        <option value="9">全部地图(不包含抖音小红书)</option>
                        <option value="10">指定地图代删除</option> -->
                    </select>
                </div>
            </div>
        </div>
        <div class="ge"></div>
        <div class="bto">
            <div class="bto_title">
                <div> 地图代标注费用:</div>
                <div class="price"><span style="font-size: 1rem;">￥</span>{{ price }}</div>
            </div>
            <div class="bto_title1">
                申请说明：商户门店/公司定位地图位置入驻需要人工上传核对审核资料，需要支付代标注服务费用（一次添加长期有效，免费使用，全套地图套餐享免费人工维护）！
            </div>
            <div class="bto_boxs ">
                <div class="bto_box">
                    <div class="bto_l">
                        <img class="bto_img" src="@/assets/zhifubao.png">支付宝支付
                    </div>
                    <div class="bto_r" @click="changepaytype(3)">
                        <img src="@/assets/clicked.png" class="w28" alt="" v-if="paytype == 3">
                        <img src="@/assets/noclicked.png" class="w28" alt="" v-else>
                    </div>
                </div>
                <!-- <div class="bto_box" @click="changepaytype(2)">
                    <div class="bto_l">
                        <img class="bto_img" src="@/assets/weixin.png">微信支付
                    </div>
                    <div class="bto_r">
                        <img src="@/assets/clicked.png" class="w28" alt="" v-if="paytype == 2">
                        <img src="@/assets/noclicked.png" class="w28" alt="" v-else>
                    </div>
                </div> -->
            </div>
            <div class="form_btn" @click="submit()">
                立即提交
            </div>



        </div>
        <div class="ge"></div>
        <div class="biaodans">
            <div class="biaodan">
                <div class="bto_title">
                    常见问题
                </div>
                <div class="forms">

                    <div v-for="(item, index) in wentilist" :key="index">
                        <div class="wenti_title1">
                            <span>{{ index + 1 }},</span>
                            {{ item.title }}
                        </div>
                        <div class="wenti_title2" v-html="item.intor">

                        </div>
                    </div>
                </div>
            </div>

        </div>




        <BottomVue :type="'2'"></BottomVue>
        <!-- <div class="rukou1">
            <a href="https://tb.53kf.com/code/client/25af8c47791b3097aa300eff2786dc860/1" target="_blank" class="rukou1_t">咨询办理</a>
        </div> -->

    </div>
</template>

<script>

// import axios from "axios";
// import datalist from "@/request/data.json";
// import datalistxs from "@/request/dataxs.json";
// import { cityCode } from "@/request/cityCode.js";
// import { totalData, areaData, monthData, pieChartRepair, pieChartComplain, pieChartInstall, mapData, tableData } from '../request/api'
import TopVue from './Top.vue'
import BottomVue from './Bottom.vue'
// import TopVue
import { mapList, mapLabel } from '../request/api'
export default {
    name: 'PayPage',
    data() {
        return {
            paytype: 3,
            popshow: false,
            checked: true,
            form: {
                name: '',
                address: '',
                phone: ''
            },
            currentIndex: 0, // 当前激活的列表项索引
            itemWidth: 200, // 每个列表项的宽度
            wentilist: [{
                title: '地图标注需要提供什么？',
                intor: '需要提供地图定位名称、定位详细地址、店铺/企业联系人名字、联系电话等，确认信息无误后即可提交信息开始审核，最快当天可以上线'

            }, {
                title: '地图标注有什么好处?',
                intor: '地图标注服务，不仅可以让您的客户更快速的找到您，也会给商户带来三大核心平台价值: 1、可以提高商户的在线曝光率，为商户开辟网络和导航“地盘" ;2、更多商户详情展示，给予商户更广泛的展示;3、提高消费转化率，让目标消费者更快速的搜到商户的位置并参考商户详情信息。'

            }, {
                title: '地图标注需要提供什么？',
                intor: '需要提供地图定位名称、定位详细地址、店铺/企业联系人名字、联系电话等，确认信息无误后即可提交信息开始审核，最快当天可以上线'

            }, {
                title: '地图标注需要提供什么？',
                intor: '需要提供地图定位名称、定位详细地址、店铺/企业联系人名字、联系电话等，确认信息无误后即可提交信息开始审核，最快当天可以上线'

            }],
            carouselWidth: 0, // 动态计算
            showIndex: 1,
            names: [], // 获取名称
            values: [], // 获取数值
            total: 0, // 总和
            userData: {},
            initData: [],
            map_id: 0,
            price: '',
            isMobile: false,
            isWeiXi: false


        }
    },
    components: {
        TopVue,
        BottomVue
    },
    created() {
        this.userData = JSON.parse(localStorage.getItem('setUserData')) || '';
        console.log('this.userData', this.userData)
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },

    computed: {
        // userData() {
        //     return this.$store.state.userData;
        // },
        carouselStyle() {
            return {
                width: `${this.carouselWidth}px`,
                // 其他样式
            };
        },
        // itemStyle() {
        //     return (index) => {
        //         console.log('index', index)
        //         // 根据index设置样式
        //         return {
        //             // 样式
        //         };
        //     };
        // }
    },

    mounted() {
        const that = this
        that.getdata1()
        that.checkMobile();
        // setInterval(() => {
        //     const firstDom = document.getElementsByClassName('chart-l-3')[0].getElementsByClassName('list-item')[0];
        //     that.showIndex++;
        //     console.log('showIndex', that.showIndex)
        //     if (that.showIndex === (that.itemslist.length - 5)) {
        //         that.showIndex = 0;
        //     }
        //     firstDom.style.marginTop = '-' + 2.5 * that.showIndex + 'vw';
        // }, 10000);



        // setInterval(that.nextItem, 3000);
        that.show = true
        console.log('that.userData', that.userData)
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                window.screenHeight = document.body.innerHeight
                that.screenWidth = window.screenWidth
                that.screenHeight = window.innerHeight
            })()
        }









        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;  // 注意月份从0开始，需要加1
        let day = currentDate.getDate();

        // 输出当前日期
        console.log(year + "-" + month + "-" + day);
        // console.log(this.$route.params.houseid,'hhhhhhhhhhhhhhhhhhhhhhhhhhhhh'); // 输出路由参数

    },

    watch: {
        map_id(newValue) {
            console.log('newValue', newValue)
            this.handleSelection();
        },

        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenWidth = val
            // this.timer = true
        },
        screenHeight(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenHeight = val
            // this.timer = true
        },



    },
    methods: {
        handleSelection() {
            var that = this
            // 使用 this.selectedValue 作为调用接口的参数
            console.log("Selected value for API call:", that.map_id);
            that.initData.forEach(function (item, index) {
                if (item.id == that.map_id) {
                    that.price = item.price
                }
                console.log(item, index); //输出数组的每一个元素

            });


            // 这里添加调用接口的代码
        },
        async getdata1() {
            await mapList({

            }).then(res => {
                console.log(res, 'res.datares.data')
                if (res) {



                    this.initData = res.data
                    this.map_id = this.initData[0].id
                    this.price = this.initData[0].price


                }

            })
        },
        itemStyle(index) {
            console.log('index', index)
            // 设置左侧位置，根据当前激活的索引和列表项宽度计算
            // let left = (this.currentIndex - index) * this.itemWidth + 'px';
            // return {
            //     width: this.itemWidth + 'px',
            //     left: left,
            //     transition: 'left 0.5s ease' // 可选的过渡效果
            // };
        },
        // 方法用于更新currentIndex，实现轮播逻辑
        nextItem() {
            this.currentIndex = (this.currentIndex + 1) % this.items.length;
        },
        open() {
            this.$alert('这是一段内容', '标题名称', {
                confirmButtonText: '确定',
                callback: action => {
                    console.log('action', action)
                    // this.$message({
                    //   type: 'info',
                    //   message: `action: ${ action }`
                    // });
                }
            });
        },
        gopage() {
            this.$router.push('./HomePage.vue');
        },
        calculateCarouselWidth() {
            // 计算carouselWidth的逻辑
        },
        isValidPhone(phoneNumber) {
            // 这里可以根据需要调整正则表达式
            let reg = /^(((\d{3,4}-)?[0-9]{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/
            return reg.test(phoneNumber);

        },
        changepaytype(type) {
            if (this.paytype == type) {
                this.paytype = 0
            }
            else {
                this.paytype = type
            }
        },
        checkMobile() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
        },
        getUrlParams() {

            // 清除旧的缓存数据

            // window.localStorage.clear() 
            let theRequest = new Object();
            let url = location.href; //获取url中"?"符后的字串 
            let strs = [];
            if (url.indexOf("?") != -1) {
                var str = url.substr(parseInt(url.indexOf("?") + 1));
                strs = str.split("&");
                for (var i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
                }
            }

            this.theRequest = theRequest;
        },

        // 监控微信浏览器

        isWeiXin() {
            let ua = window.navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) != "micromessenger") {
                this.isWeiXi = false

                // 重新存储新的token（在外部浏览器打开支付完成后是没有token这些数据的所以需要在浏览器一打开的时候就去存一次数据）

                window.localStorage.setItem("channelId", this.theRequest.channelId);
                window.localStorage.setItem("userKey", JSON.stringify(this.theRequest.userKey));
                window.localStorage.setItem("userToken", JSON.stringify(this.theRequest.userToken));
                if (this.theRequest.memberTypeName) {

                    // 调用支付宝支付

                    this.zfbPayBuy(this.theRequest)
                } else {
                    this.zfbPayBuySocial(this.theRequest)
                }

            } else {
                this.isWeiXi = true

            }
        },

        // 支付宝支付(会员)

        zfbPayBuy(data) {

            // 请求接口拿到接口返回的支付表单（接口直接返回的，我们直接装进页面就可以了）

            this.axios.payBuy(data).then(res => {
                if (res.status == 0) {
                    let payHtml = document.querySelector(".payform");
                    payHtml.innerHTML = res.result;
                    let paySub = payHtml.getElementsByTagName("input")[1];
                    paySub.click()

                }
            })
        },

        //支付宝支付（社保）

        zfbPayBuySocial(data) {
            this.axios.buySocial(data).then(res => {
                if (res.status == 0) {
                    let payHtml = document.querySelector(".payform")
                    payHtml.innerHTML = res.result
                    let paySub = payHtml.getElementsByTagName("input")[1]
                    paySub.click()
                }
            })
        },
   
    async submit() {

        var that = this

        console.log('that.form', that.form)
        if (!that.checked) {

            that.$message('请勾选用户协议');
            return
        }
        if (!that.userData.name) {


            // this.$notify({
            //     title: '提示',
            //     message: '请填写定位名称（店名/公司名）',
            //     duration: 1000

            // });
            that.$message('请填写定位名称（店名/公司名）');
            // uni.$u.toast('')
            return
        }
        if (!that.userData.address) {
            // uni.$u.toast('请选择收货地址')
            // this.$notify({
            //     title: '提示',
            //     message: '请填写详细地址（省/市/区/街道/路/门牌号）',
            //     duration: 1000

            // });
            that.$message('请填写详细地址（省/市/区/街道/路/门牌号）');

            return
        }
        if (!that.userData.phone || !that.isValidPhone(that.userData.phone)) {
            // uni.$u.toast('请填写电话!')
            // this.$notify({
            //     title: '提示',
            //     message: '填写联系人手机号码',
            //     duration: 1000

            // });
            that.$message('请正确填写联系人手机号码');

            return
        }
        else {
            await mapLabel({
                payment_way: that.paytype, //支付方式 1：余额支付 2：微信支付 3：支付宝支付
                payment_channel: that.paytype == 3 ? 'alipay' : 'wechat', //支付渠道 wechat：微信 alipay：支付宝
                payment_channel_method: that.isMobile ? 'wap' : 'web', //支付渠道中的支付方式 
                //                     miniapp：小程序
                // mp：微信公众号支付 
                // web：PC端支付
                // wap：手机网页支付
                map_id: that.map_id, //地图ID
                mobile: that.userData.phone, //联系人手机
                label_title: that.userData.name, //标注名称
                detail_address: that.userData.address //详细地址
            }).then(res => {
                console.log(res, 'res.datares.data')
                if (res.data.payment_result) {
                    var paydata = res.data.payment_result
                    console.log('paydata', paydata)
                    if (that.paytype == 3) {
                        // return
                        //支付宝支付
                        // const div = document.createElement("div");
                        // div.innerHTML = paydata; //此处form就是后台返回接收到的数据
                        // document.body.appendChild(div);
                        // document.forms[0].submit();
                        const div = document.createElement('div');
                            // 渲染这个div中的表单（res.data.pay_img就是后端返回的表单）
                            div.innerHTML = paydata;
                            // 进行元素追加渲染
                            document.body.appendChild(div);
                            // 提交表单(alipaysubmit这个名字是和表单上的name一致的，要注意别搞错了)
                            document.forms['alipay_submit'].submit();
                    }
                    else {
                        //微信支付
                    }
                }

            })
        }

    }

}

}

</script>

<style scoped lang="scss">
.bg {
    margin: 0;
    width: 100vw;


    /* background-color: #0f2461; */
    background-color: rgba(255, 255, 255, 0.5);



}

.red-star {
    color: red;
    margin-right: 5px;

}

.topxinxi {
    padding: .2rem 3% .3rem;
}




.top_title {
    width: 100%;

    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-size: 1.5rem;

    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;


    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 10px;

    // text-align: center;

    padding: 10px 0;
}

.top_title1 {
    width: 100%;

    font-weight: 400;
    line-height: 1.5;
    color: red;
    font-size: 1.5rem;

    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;



    padding-bottom: 10px;

    // text-align: center;

    padding: 10px 0;
}

.top_box {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.top_box_l {
    font-size: 1.5rem;
    color: #39f;
    width: 28%;
    font-family: Microsoft YaHei, Helvetica Neue, Helvetica, Roboto, Heiti SC, STHeiTi, Arial, sans-serif;
    font-weight: normal;
    display: inline-block;
}

.top_box_r {
    font-size: 1.5rem;
    font-family: Microsoft YaHei, Helvetica Neue, Helvetica, Roboto, Heiti SC, STHeiTi, Arial, sans-serif;
    font-weight: normal;
}

.mid,
.bto {
    padding: .2rem 3% .3rem;
}

.mid_t {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    line-height: 1.5;
    color: red;
    font-size: 1.25rem;

    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;


    text-align: center;

    padding: 10px 0;
}

.bto_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-size: 1.5rem;

    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;


    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 10px;

    // text-align: center;

    padding: 10px 0;
}

.price {
    color: red;
    font-weight: bold;
    font-size: 1.5rem;
}

.bto_title1 {
    width: 100%;

    font-weight: 400;
    line-height: 1.5;
    color: red;
    font-size: 1.5rem;

    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;



    padding-bottom: 10px;

    // text-align: center;

    padding: 10px 0;
}

.bto_img {
    height: 50px;
    width: 50px;
    display: inline-block;
    margin-right: 5px;

}

.bto_box {
    // margin: 10px 0;
    justify-content: space-between;
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid #dfdfdf;
    border-top: 1px solid #dfdfdf;
    align-items: center;
}

.bto_l {
    display: flex;
    font-size: 1.25rem;
    align-items: center;
}

.biaodans {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.biaodan {
    padding: .2rem 3% .3rem;
    // padding-right: 15px;
    // padding-left: 15px;
    // margin-right: auto;
    // margin-left: auto;
}

.form_input {
    width: 95%;
    margin-top: 20px;

    margin-bottom: 20px;

    height: calc(1.5em);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.form_btn {
    margin: 30px 0;
    border-radius: 10px;
    padding: .375rem .75rem;
    cursor: pointer;
    font-weight: 400;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;


    width: 95%;
    text-align: center;

    &:hover {

        background-color: #0069d9;
        border-color: #0062cc;

    }
}


.form_btn :hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

.gouxuan {}

.ge {
    height: 10px;
    clear: both;
    background: #f1f1f1;
}

.carousel-list {
    max-height: 400px;
    overflow: hidden;
    transition: all 0.5s ease;
}

.list-item {
    transition: all 0.5s ease;

    .tag {
        // background: url('./assets/icon-tag-blue.svg') no-repeat;
        // 更多样式...
    }

    .jdt-bar {
        background-color: #87f7c7;
        // 更多样式...
    }

    // 更多样式...
}

.wenti_title1 {
    margin: 10px;

    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5;
    color: #111;
}

.wenti_title2 {
    margin: 10px;
    padding: 8px 15px;
    background: #f1f1f1;
    border-radius: 5px;
    font-size: 1rem;

}

// .carousel-container {
//     position: relative;
//     overflow: hidden;
//     width: 100%;
// }

// .carousel-list {
//     position: absolute;
//     width: 100%;
// }

// .carousel-list li {
//     position: absolute;
//     list-style: none;
// }
li {
    list-style: none;
}

.scroll-item {
    // margin: 10px;
    margin-bottom: 5px;
    padding: 5px 0;
    overflow: hidden;
    border-bottom: 1px solid #f1f1f1;
    height: auto !important;
}

.carousel-list {
    overflow: hidden;
    position: relative;
    height: 400px;
    animation: scroll 10s linear infinite;
}

.carousel-item {
    position: absolute;
    width: 100%;
    height: 100px;
    line-height: 100px;
}


.scroll-container {
    overflow: hidden;
    position: relative;
    height: 400px;
    /* 根据需要调整高度 */
}

.scroll-container ul {
    position: absolute;
    width: 100%;
    top: 0;
    animation: scroll 20s linear infinite;
    /* 调整时间以改变滚动速度 */
}

// .scroll-item {
//     height: 100px;
//     /* 根据需要调整高度 */
//     line-height: 100px;
//     /* 使元素垂直居中 */
//     text-align: center;
// }

@keyframes scroll {
    0% {


        transform: translateY(0%);


    }




    100% {


        transform: translateY(-100%);


    }

}

.form_btn {
    margin: 30px 0;
    border-radius: 10px;
    padding: .375rem .75rem;
    cursor: pointer;
    font-weight: 400;
    height: 50px;
    line-height: 50px;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;


    width: 95%;
    text-align: center;

    &:hover {

        background-color: #0069d9;
        border-color: #0062cc;

    }
}


.form_btn :hover {
    background-color: #0069d9;
    border-color: #0062cc;
}
</style>
