<template>
  <div id="app">



    <div class="content" @scroll="onScroll">

      <router-view></router-view>
      <!-- <div class="image-container" :class="{ 'animate': isAnimated }" :style="{ top: scrollTop + 'px' }" @click="kefu()">

        <img src="@/assets/clickbtn_4.png" class="wkefu" alt="">
      </div>
      <div class="mini-btn mini-btn2" id="mini-btn" @click="kefu()"
        style="cursor: pointer; display: block; position: fixed; right: 0px; bottom: 30px; z-index:1000; width: 3rem; border-radius: 4px; overflow: hidden; background: rgb(31, 140, 235); box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 6px, rgba(0, 0, 0, 0.16) 0px 2px 32px;">
        <div class="mini-btn-info" style="position: relative;width:3rem;height: 60px;"><span
            style="width:100%;height:100%;position:absolute;left:0;top:0;z-index:1;background: center center no-repeat;background-image: url(https://www4.53kf.com/style/chat/new2017/image/png/img-info24.png);background-image: url(https://www4.53kf.com/style/chat/new2017/image/svg/img-info24.svg), none;"></span><span
            style="width:100%;height:100%;position:absolute;left:0;top:0;background:#000;opacity:0.2;filter:alpha(opacity=20);"></span><span
            id="info-num"
            style="display:none;position: absolute;top: 5px;right: 5px;z-index:10;height: 14px;min-width: 14px;line-height: 14px;border-radius: 7px;background: red;font-size:1rem;color: #FFFFFF;letter-spacing: 0.47px;text-align: center;">0</span>
        </div>
        <div class="mini-btn-middle"
          style="font-weight:normal;line-height: 20px;padding:  13px 16px;font-size: 1rem;color: #FFFFFF;letter-spacing: 5px;word-wrap: break-word;text-align: center;">
          和我们在线交谈!</div>
      </div> -->
      <!-- 滚动区域的内容 -->
    </div>





  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  data() {
    return {
      isAnimated: false,
      headerHeight: 0,
      scrollTop: 0
    };
  },
  mounted() {
    this.headerHeight = this.$refs.header.clientHeight;
    console.log(' this.headerHeight ', this.headerHeight)
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    document.title = '地图标注中心';
    this.screenHeight = window.innerHeight; // 获取初始屏幕高度
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    kefu(){
      // const newUrl = 'https://www4c1.53kf.com/webCompany.php?kf_sign=zcxNzMTczMcxMDEzMDg1MTM2OTQzMDA1NzI3ODA3MDE=&arg=10780701&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2F6bd.hzhjjykj.cn%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=ddff6d68c57826f3f66ca1dd0123cdf6&is_group=&is_group=&timeStamp=1733725173985&ucust_id=';
    
      // window.open(newUrl, '_blank')

    },
    onScroll(event) {
      // 在这里处理滚动事件

      console.log('event', event)
      const scrollTop = event.target.scrollTop;
      this.scrollTop = scrollTop + 300
      if (scrollTop > this.headerHeight && !this.isAnimated) {
        this.isAnimated = true;
      } else if (scrollTop <= this.headerHeight && this.isAnimated) {
        this.isAnimated = false;
      }
    }
  }
}
</script>
<style>
/* 使用自定义字体 */
.custom-font {
  font-family: 'PingFangSC-zy';
}
</style>
<style>
/*每个页面公共css */
@import "../src/assets/style.css";
</style>
<style>
#app {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

.title,
.title1 {
  font-size: 25px;
}

.content {
  height: 100vh;
  /* 内容区域高度，可以根据需要调整 */
  overflow-y: scroll;
  position: relative;
}

.image-container {
  position: absolute;
  top: 300px;
  cursor: pointer;
  /* 图片初始位置 */
  width: 100%;
  right:10px;
  z-index: 9999;
  width: 130px;
  transition: top 1s ease;
}

.image-container.animate {}

.wkefu {
  width: 130px;
  height: 150px;

}
</style>
