// 首先导入刚刚封装好的request.js文件  路径存储根据自己修改
import request from '@/request/request' 


export function mapList(data) {
  return request({
   method:'POST',
   url:'/api/map/mapList',
   data: data
  })
}
export function mapSubmit(data) {
  return request({
   method:'POST',
   url:'/api/map/mapSubmit',
   data: data
  })
}

export function mapWhy(data) {
  return request({
   method:'POST',
   url:'/api/map/mapWhy',
   data: data
  })
}

export function mapCase(data) {
  return request({
   method:'POST',
   url:'/api/map/mapCase',
   data: data
  })
}

export function pConfig(data) {
  return request({
   method:'POST',
   url:'/api/map/pConfig',
   data: data
  })
}
export function mapLabel(data) {
  return request({
   method:'POST',
   url:'/api/map/mapLabel',
   data: data
  })
}
export function problem(data) {
  return request({
   method:'POST',
   url:'/api/map/problem',
   data: data
  })
}
export function newMapLabel(data) {
  return request({
   method:'POST',
   url:'/api/map/newMapLabel',
   data: data
  })
}
export function index(data) {
  return request({
   method:'POST',
   url:'/api/agreement/index',
   data: data
  })
}










