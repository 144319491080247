import Vue from "vue";
import VueRouter from "vue-router";

import MenuPage from '@/components/MenuPage';
import HomePage from "@/components/HomePage.vue";
import SendPage from "@/components/SendPage.vue";

import PayPage from "@/components/PayPage.vue"
// 注册vue-router中的所有组件
Vue.use(VueRouter);

// const allRouter = [...routers, ...echartsRouters];
const router = new VueRouter({
  mode: "history",
  routes: [

    {
      path: '/',
      name: 'MenuPage',
      component: MenuPage,

    },
    {
      path: '/HomePage',
      name: 'HomePage',
      component: HomePage,

    },
    {
      path: '/SendPage',
      name: 'SendPage',
      component: SendPage,

    },
    {
      path: '/PayPage',
      name: 'PayPage',
      component: PayPage,

    },
   
    // {
    //   path: '/MenuTwoVue',
    //   name: 'MenuTwo',
    //   component: MenuTwo,

    // },
    // {
    //   path: '/MenuThreeVue',
    //   name: 'MenuThree',
    //   component: MenuThree,

    // },
    // {
    //   path: '/MenuFourVue',
    //   name: 'MenuFour',
    //   component: MenuFour,

    // },
    // {
    //   path: '/MenuFiveVue',
    //   name: 'MenuFive',
    //   component: MenuFive,

    // }


    // ...其他路由
  ]
  //   routes: allRouter
});

export default router;

