<!--  -->
<template>
  <div>
    <div class="top flex-box ptb10 flex-between">
     
      
      <!-- <img src="../assets/back.png" class="w40" alt="" @click="back"> -->
      <div class="top_title">
        <img src="../assets/logo.png" class="w200" alt="" @click="back">
        <!-- 兴山县智慧广电公共服务管理平台 -->
        <!-- <dv-decoration-5 style="width:400px;height:40px;" /> -->
      </div>
      
      <div class="top_time">
       
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopPage',
  data() {
    return {
      currentDate:'',
      currentWeekDay:""
    }
  },
  mounted() {
   
   this.getCurrentDateAndWeekDay()
  },
  methods: {
    getCurrentDateAndWeekDay() {
      const now = new Date();
      const days = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
 
      this.currentDate = `${now.getFullYear()}年${now.getMonth() + 1}月${now.getDate()}日`;
      this.currentWeekDay = days[now.getDay()];
    },
    home() {
      this.$router.push('/');
    },
    back() {
      this.$router.push('/');
    }
  }
}

</script>

<style scoped>
.w200 {
  width: 230px;
  max-height: 60px;
  cursor: pointer;
}

.top {
  padding: auto 20px;
  height: 60px;
  text-align: center;
  display: flex;align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: #1a86ff;
}

.top_title{
  width:80%;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 10px 0;
  /* text-align: center; */
 
}
</style>
