<!--  -->
<template>
    <div class="bg">
        <div class="pay-mask" v-show="isWeiXi" @click="isWeiXi = !isWeiXi">
            <div style="justify-content: space-between;display: flex;align-items: center;margin: 100px;">
                <div>

                </div>
                <div>
                    <img style="width: 50px;height: 50px;" src="@/assets/share.png" alt="">
                </div>
            </div>
            点击右上角选择浏览器打开
        </div>
        <div class="pay-mask1" v-show="dialogVisible" @click="dialogVisible = !dialogVisible" style="padding: 100px;">

            <div style="background-color: #fff;padding: 20px;width: 100%;">
                <div class="mask1_title">客户服务协议</div>
                <div v-html="indextext"></div>
            </div>
        </div>
        <TopVue></TopVue>

        <div class="biaodans" @click="gopage">
            <div class="biaodan">
                <div class="title">
                    填写相关信息
                </div>
                <div class="forms">
                    <div class="form">
                        <div class="form_t">
                            <span class="red-star">*</span>
                            标注名称
                        </div>
                        <input type="text" placeholder="填写定位名称（店名/公司名" v-model="form.name" class="form_input">
                    </div>
                    <div class="form">
                        <div class="form_t">
                            <span class="red-star">*</span>
                            详细经营地址
                        </div>
                        <input type="text" placeholder="填写详细地址（省/市/区/街道/路/门牌号）" v-model="form.address"
                            class="form_input">
                    </div>
                    <div class="form">
                        <div class="form_t">
                            <span class="red-star">*</span>
                            填写联系人手机号码
                        </div>
                        <input type="text" placeholder="填写联系人手机号码" v-model="form.phone" class="form_input">
                    </div>
                </div>
                <div class="form_btn" @click="submit()">
                    立即提交
                </div>

                <div class="gouxuan flex-box">

                    <img src="../assets/clicked.png" class="w20" alt="" v-if="checked" @click="checked = !checked">
                    <img src="../assets/noclicked.png" class="w20" alt="" v-else @click="checked = !checked">
                    <span style="margin-left: 10px;">
                        我已阅读并同意
                    </span>
                    <span style="color: rgb(26, 134, 255);cursor: pointer;" @click="dialogVisible = true">
                        【客户服务协议】
                    </span>
                </div>
                <p><span
                        style="color: rgb(26, 134, 255);">申请说明：</span>填写好并提交基本标注信息，待地图平台审核通过，商户信息将在地图上以气泡形式展示，地图页面会显示详情，地图海量用户可以通过PC、移动端两个入口访问并搜索到您的位置
                </p>
                <p><span style="color: rgb(26, 134, 255);">上线时间:</span> 1-7个工作日完成上线，一次添加长久有效
                </p>


            </div>

        </div>
        <div class="ge"></div>
        <div class="biaodans">
            <div class="biaodan">


                <div class="title">
                    最新申请记录
                </div>
                <div class="forms">
                    <!-- <div class="chart chart-l-3 list-box">
                        <div v-for="(item, index) in itemslist" :key="index" :class="{ 'bgy': index % 2 == 0 }"
                            class="list-item">
                          
                            <div class="name">{{ item.name }}</div>
                            <div class="jdt-box">
                                <div :style="{ width: item.time + '%' }" class="jdt-bar"></div>
                            </div>
                            <div class="value">{{ item.type }}</div>
                        </div>
                    </div> -->
                    <!-- <div class="carousel-container">
                        <ul class="carousel-list">
                            <li v-for="(item, index) in itemslist" :key="index" :style="itemStyle(index)"
                                class="carousel-item">
                                <div class="libox">
                                    <span style="color: #00a2d4;">用户：</span>
                                    <span style="margin: 0 20px;">{{ item.name }}</span>

                                    <span style="margin: 0 20px;">{{ item.time }}</span>
                                    <span style="margin: 0 20px;color: #1e90ff;">已申请：</span>
                                    <span>{{ item.type }}</span>
                                </div>
                                <div class="libox">
                                    <span style="color: #00a2d4;">地址：</span>
                                    <span style="margin: 0 20px;">{{ item.address }}</span>


                                </div>

                            </li>
                        </ul>
                    </div> -->
                    <div class="scroll-container11" v-if="itemslist.length == 0">
                        <el-empty description="暂无记录"></el-empty>
                    </div>
                    <div class="scroll-container" v-else>
                        <ul>
                            <li v-for="(item, index) in itemslist" :key="index" class="scroll-item">
                                <div class="libox">
                                    <span style="color: #00a2d4;">用户：</span>
                                    <span style="margin: 0 0px;">{{ item.mobile }}</span>

                                    <span style="margin: 0 20px;">{{ item.createtime1 }}</span>

                                    <span style="margin: 0 0px;color: #1e90ff;">已申请：</span>
                                    <!-- <br v-if="screenWidth<=800"> -->
                                    <div v-if="screenWidth <= 800">{{ item.map_info.name }}</div>
                                    <span v-else>{{ item.map_info.name }}</span>
                                </div>
                                <div class="libox">
                                    <span style="color: #00a2d4;">地址：</span>
                                    <span style="margin: 0 20px;">{{ item.detail_address }}</span>


                                </div>
                            </li>
                        </ul>
                    </div>


                </div>

            </div>

        </div>
        <div class="ge"></div>
        <div class="biaodans">
            <div class="biaodan">

                <div class="title">
                    常见问题
                </div>
                <div class="forms">

                    <div v-for="(item, index) in wentilist" :key="index">
                        <div class="wenti_title1">
                            <span>{{ index + 1 }},</span>
                            {{ item.title }}
                        </div>
                        <div class="wenti_title2" v-html="item.intro">

                        </div>
                    </div>
                </div>
            </div>

        </div>




        <BottomVue :type="'2'"></BottomVue>
        <!-- <div class="rukou1">
            <a href="https://tb.53kf.com/code/client/25af8c47791b3097aa300eff2786dc860/1" target="_blank" class="rukou1_t">咨询办理</a>
        </div> -->



    </div>
</template>

<script>

// import axios from "axios";
// import datalist from "@/request/data.json";
// import datalistxs from "@/request/dataxs.json";
// import { cityCode } from "@/request/cityCode.js";
// import { totalData, areaData, monthData, pieChartRepair, pieChartComplain, pieChartInstall, mapData, tableData } from '../request/api'
import TopVue from './Top.vue'
import { problem, newMapLabel, index } from '../request/api'



import BottomVue from './Bottom.vue'
// import TopVue
export default {
    name: 'MenuPage',
    data() {
        return {
            screenWidth: document.body.clientWidth,//初始化宽度
            popshow: false,
            checked: true,
            form: {
                name: '',
                address: '',
                phone: ''
            },
            dialogVisible: false,
            currentIndex: 0, // 当前激活的列表项索引
            itemWidth: 200, // 每个列表项的宽度
            wentilist: [],
            itemslist: [
                // 数据列表\


                // ...
            ],
            carouselWidth: 0, // 动态计算



            showIndex: 1,
            names: [], // 获取名称
            values: [], // 获取数值
            total: 0, // 总和
            isWeiXi: false,
            indextext: ''



        }
    },
    components: {
        TopVue,
        BottomVue
    },
    created() {

    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    computed: {

        carouselStyle() {
            return {
                width: `${this.carouselWidth}px`,
                // 其他样式
            };
        },
        // itemStyle() {
        //     return (index) => {
        //         console.log('index', index)
        //         // 根据index设置样式
        //         return {
        //             // 样式
        //         };
        //     };
        // }
    },

    mounted() {
        const that = this
        // setInterval(() => {
        //     const firstDom = document.getElementsByClassName('chart-l-3')[0].getElementsByClassName('list-item')[0];
        //     that.showIndex++;
        //     console.log('showIndex', that.showIndex)
        //     if (that.showIndex === (that.itemslist.length - 5)) {
        //         that.showIndex = 0;
        //     }
        //     firstDom.style.marginTop = '-' + 2.5 * that.showIndex + 'vw';
        // }, 10000);
        this.problemdata()
        this.itemslistdata()
        this.indexdata()



        // setInterval(that.nextItem, 3000);
        that.show = true
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                window.screenHeight = document.body.innerHeight
                that.screenWidth = window.screenWidth
                that.screenHeight = window.innerHeight
            })()
        }










    },

    watch: {

        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenWidth = val
            // this.timer = true
        },
        screenHeight(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenHeight = val
            // this.timer = true
        },



    },
    methods: {
        handleClose(done) {
            console.log('done', done)
        },
        async indexdata() {
            var that = this
            await index({
                type: 1
            }).then(res => {
                console.log(res, 'res.datares.data')
                if (res) {



                    that.indextext = res.data.content





                }

            })
        },
        async itemslistdata() {
            var that = this
            await newMapLabel({

            }).then(res => {
                console.log(res, 'res.datares.data')
                if (res) {



                    that.itemslist = res.data
                    that.itemslist.forEach(function (item, index) {
                        console.log('item, index', item, index)
                        item.mobile = item.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
                        item.createtime1 = that.formatterDate(item.createtime * 1000)
                    });






                }

            })
        },
        formatterDate(val) {
            // 将时间转换为 XX年XX月XX日XX时XX分XX秒格式
            let newDateObj = new Date(val)
            let year = newDateObj.getFullYear()
            let month = newDateObj.getMonth() + 1
            let day = newDateObj.getDate()

            month = month > 9 ? month : '0' + month
            day = day > 9 ? day : '0' + day
            // 发送时间
            return `${year}-${month}-${day}`
        },

        async problemdata() {
            await problem({

            }).then(res => {
                console.log(res, 'res.datares.data')
                if (res) {



                    this.wentilist = res.data


                }

            })
        },
        itemStyle(index) {
            console.log('index', index)
            // 设置左侧位置，根据当前激活的索引和列表项宽度计算
            // let left = (this.currentIndex - index) * this.itemWidth + 'px';
            // return {
            //     width: this.itemWidth + 'px',
            //     left: left,
            //     transition: 'left 0.5s ease' // 可选的过渡效果
            // };
        },
        // 方法用于更新currentIndex，实现轮播逻辑
        nextItem() {
            this.currentIndex = (this.currentIndex + 1) % this.items.length;
        },
        open() {
            this.$alert(this.indextext, '服务协议', {
                confirmButtonText: '确定',
                callback: action => {
                    console.log('action', action)
                    // this.$message({
                    //   type: 'info',
                    //   message: `action: ${ action }`
                    // });
                }
            });
        },
        gopage() {
            // this.$router.push('/HomePage');
        },
        calculateCarouselWidth() {
            // 计算carouselWidth的逻辑
        },
        isValidPhone(phoneNumber) {
            // 这里可以根据需要调整正则表达式
            let reg = /^(((\d{3,4}-)?[0-9]{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/
            return reg.test(phoneNumber);

        },
        submit() {

            var that = this

            console.log('that.form', that.form)
            if (!that.checked) {
                // uni.$u.toast('请勾选用户协议!')

                // this.$notify({
                //     title: '提示',
                //     message: '请勾选用户协议',
                //     duration: 1000

                // });
                this.$message('请勾选用户协议');
                return
            }
            if (!that.form.name) {


                // this.$notify({
                //     title: '提示',
                //     message: '请填写定位名称（店名/公司名）',
                //     duration: 1000

                // });
                this.$message('请填写定位名称（店名/公司名）');
                // uni.$u.toast('')
                return
            }
            if (!that.form.address) {
                // uni.$u.toast('请选择收货地址')
                // this.$notify({
                //     title: '提示',
                //     message: '请填写详细地址（省/市/区/街道/路/门牌号）',
                //     duration: 1000

                // });
                this.$message('请填写详细地址（省/市/区/街道/路/门牌号）');

                return
            }
            if (!that.form.phone || !that.isValidPhone(that.form.phone)) {
                // uni.$u.toast('请填写电话!')
                // this.$notify({
                //     title: '提示',
                //     message: '填写联系人手机号码',
                //     duration: 1000

                // });
                that.$message('请正确填写联系人手机号码');

                return
            }
            else {
                // ...mapState({sum:'sum',school:'school'}),
                let ua = window.navigator.userAgent.toLowerCase();
                if (ua.match(/MicroMessenger/i) != "micromessenger") {
                    this.isWeiXi = false
                    localStorage.setItem('setUserData', JSON.stringify(that.form));
                    this.$store.commit('setUserData', { name: that.form.name, phone: that.form.phone, address: that.form.address });
                    that.$router.push('/PayPage');

                } else {
                    this.isWeiXi = true

                }


            }

        }

    }
}


</script>

<style scoped lang="scss">
.bg {
    margin: 0;
    width: 100vw;


    /* background-color: #0f2461; */
    background-color: rgba(255, 255, 255, 0.5);



}

.red-star {
    color: red;
    margin-right: 5px;

}

.title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-size: 1.25rem;

    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;


    text-align: center;

    padding: 10px 0;
}

.biaodans {
    width: 100%;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.biaodan {
    width: 85%;
    // padding-right: 15px;
    // padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.form_input {
    width: 95%;
    margin-top: 20px;

    margin-bottom: 20px;

    height: calc(1.5em);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.form_btn {
    margin: 30px 0;
    border-radius: 10px;
    padding: .375rem .75rem;
    cursor: pointer;
    font-weight: 400;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;


    width: 95%;
    text-align: center;

    &:hover {

        background-color: #0069d9;
        border-color: #0062cc;

    }
}


.form_btn :hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

.gouxuan {}

.ge {
    height: 10px;
    clear: both;
    background: #f1f1f1;
}

.carousel-list {
    max-height: 400px;
    overflow: hidden;
    transition: all 0.5s ease;
}

.list-item {
    transition: all 0.5s ease;

    .tag {
        // background: url('./assets/icon-tag-blue.svg') no-repeat;
        // 更多样式...
    }

    .jdt-bar {
        background-color: #87f7c7;
        // 更多样式...
    }

    // 更多样式...
}

.wenti_title1 {
    margin: 10px 0;

    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5;
    color: #111;
}

.wenti_title2 {
    margin: 10px 0;
    padding: 8px 15px;
    background: #f1f1f1;
    border-radius: 5px;
    font-size: 1rem;

}
.mask1_title{
    font-size: 1rem;
    justify-content:center;
    align-items: center;
    display: flex;
}

// .carousel-container {
//     position: relative;
//     overflow: hidden;
//     width: 100%;
// }

// .carousel-list {
//     position: absolute;
//     width: 100%;
// }

// .carousel-list li {
//     position: absolute;
//     list-style: none;
// }
li {
    list-style: none;
}

.pay-mask {
    width: 100%;
    min-height: 100%;
    position: fixed;
    z-index: 99;
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    text-align: center;

    // background-image: url('../../src/assets/clicked.png');
    background-repeat: no-repeat;
}
.pay-mask1 {
    // width: 100%;
    min-height: 100%;
    position: fixed;
    z-index: 99;
    background-color: rgba(0, 0, 0, .6);
    
    text-align: center;

    // background-image: url('../../src/assets/clicked.png');
    background-repeat: no-repeat;
}

.scroll-item {
    // margin: 10px;
    margin-bottom: 5px;
    padding: 5px 0;
    overflow: hidden;
    border-bottom: 1px solid #f1f1f1;
    height: auto !important;
}

.carousel-list {
    overflow: hidden;
    position: relative;
    height: 400px;
    animation: scroll 10s linear infinite;
}

.carousel-item {
    position: absolute;
    width: 100%;
    height: 100px;
    line-height: 100px;
}



.scroll-container {
    font-size: 1rem;
    overflow: hidden;
    position: relative;
    height: 400px;
    /* 根据需要调整高度 */
}

.scroll-container ul {
    position: absolute;
    margin: 0;
    padding-inline-start: 0px !important;
    width: 100%;
    top: 0;
    animation: scroll 20s linear infinite;
    /* 调整时间以改变滚动速度 */
}

// .scroll-item {
//     height: 100px;
//     /* 根据需要调整高度 */
//     line-height: 100px;
//     /* 使元素垂直居中 */
//     text-align: center;
// }

@keyframes scroll {
    0% {


        transform: translateY(0%);


    }




    100% {


        transform: translateY(-100%);


    }

}
</style>
